// Charge la base commune
import './common.js'

// Offers Search Form
async function getOsForm ($f) {
  import(/* webpackChunkName: "offersSearchForm" */ './js/components/offersSearchForm.js').then(() => {
    /* global Waypoint */
    Waypoint.refreshAll()
    $f.load(false)
  })
}

// Offers Autocomplete
async function getOffersAutocomplete ($f) {
  import(/* webpackChunkName: "offersAutocomplete" */ './js/components/offersAutocomplete.js').then(module => {
    module.offersAutocomplete.init($f)
  })
}

// Home carousel
async function getHomeCarousel ($f) {
  import(/* webpackChunkName: "homeCarousel" */ './js/components/homeCarousel.js').then(() => {
    Waypoint.refreshAll()
    $f.each(function (i, elem) {
      $(elem).load(false)
    })
  })
}

// actions principales communes à toutes les pages
$(document).ready(function () {
  // gestion de la recherche d'offres
  const $b = $('form[name="osForm"]')
  if ($b.length) {
    $b.waypoint(function () {
      $b.load()
      getOsForm($b)
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // filtres des offres autocomplete
  $('form[name="osForm"] input[name="location"]').on('click keyup focus', function () {
    $(this).blur().off().attr('disabled', '').val('Veuillez patienter...')
    getOffersAutocomplete($(this).parents('form'))
  })

  // gestion des carousels de l'accueil
  const $c = $('.carouselHome')
  if ($c.length) {
    $c.waypoint(function () {
      $c.each(function (i, elem) {
        $(elem).load()
      })
      getHomeCarousel($c)
      this.destroy()
    }, {
      offset: '100%'
    })
  }
})
