/* fichier JS de base commun quelques soient les versions du projet web */

// import 'bootstrap'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'

// lazy loading => https://github.com/aFarkas/lazysizes
import lazySizes from 'lazysizes'
/* global Waypoint */
import 'waypoints/lib/jquery.waypoints.min.js'

// bootstrap => customisez bootstrap directement depuis un fichier custom et sélectionnez les modules communs utilisés sur toutes les pages du site au lieu de tout importer
import './scss/bootstrap'
// global styles
import './scss/custom'

// Scripts librairies
import { handle, sticky } from './js/libs/misc'
import './js/libs/utils'
lazySizes.cfg.expand = 80
lazySizes.cfg.expFactor = 1
$('body').removeClass('no-js')

// lazysizes : add simple support for background images :
document.addEventListener('lazybeforeunveil', (e) => {
  const bg = e.target.getAttribute('data-background')
  if (bg) e.target.style.backgroundImage = 'url(' + bg + ')'
})

// Scroll to top
async function getScrollToTop () {
  import(/* webpackChunkName: "scrollToTop" */ './js/components/scrollToTop.js')
}

// Swiper Carousel
async function getSwiperCarousel () {
  import(/* webpackChunkName: "swiperCarousel" */ './js/components/swiperCarousel.js').then(() => {
    Waypoint.refreshAll()
  })
}

// Image boxes
async function getImgBoxes () {
  import(/* webpackChunkName: "imgBoxes" */ './scss/components/imgBoxes.scss').then(() => {
    Waypoint.refreshAll()
  })
}

// Actions principales communes à toutes les pages
$(document).ready(function () {
  // sur le scroll
  const w = $(window)
  w.scroll(function () {
    if (w.scrollTop() >= 130 && !$('#scrollUp').length) { getScrollToTop() }
    sticky()
  })

  // gestion des carousel
  const $s = $('.swiper-carousel')
  if ($s.length) {
    $s.waypoint(function () {
      getSwiperCarousel()
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion des images box
  const $i = $('.img-box')
  if ($i.length) {
    $i.waypoint(function () {
      getImgBoxes()
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // boutons liens
  $('button[data-toggle="link"]').on('click', function () {
    if ($(this).attr('data-target') === 'self') { document.location.href = $(this).attr('data-href') } else { window.open($(this).attr('data-href')) }
  })

  // ancre
  $('.scroll').each(function () {
    const me = $(this)
    me.on('click', function (e) {
      const t = ($(e.target).is('a')) ? this.hash : $(this).attr('target')
      e.preventDefault()
      if (me.length > 0) {
        $('html, body').animate({
          scrollTop: ($(t).offset().top - 80) - -1
        })
      }
    })
  })

  // refresh waypoints au chargement d'une image
  $('.lazyload').on('lazyloaded', () => {
    Waypoint.refreshAll()
  })

  // handle sur toutes les pages du site
  handle()
  sticky()
})
