/**
 * Fonction native jquery qui affiche un loader à un élément en cours de chargement (durant un import dynamique par exemple)
 * @param {boolean} i = true Affiche ou non le loader
 */
$.fn.load = function(i = true) {
  if (i) {
    this.find('.load').stop(true, true).animate({opacity: .5}, 200)
    this.find('input, button, textarea, select').prop('disabled', true)
    $(this.find('.processing')[0]).stop(true, true).show().animate({opacity: 1}, 200)
  } else {
    this.addClass('loaded')
    this.find('.load').stop(true, true).animate({opacity: 1}, 200)
    this.find('input, button, textarea, select').prop('disabled', false)
    $(this.find('.processing')[0]).stop(true, true).animate({opacity: 0}, 200, function() { $(this).hide() })
  }
}

// Wait while GMap API is loading
export function dtectMap(action) {
  if ( $('body').hasClass('maploaded') ) {
    action()
  } else {
    setTimeout(function() {
      dtectMap(action)
    }, 200)
  }
}
